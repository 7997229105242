import { Box, Flex, Link,Image} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const PageWrapper= styled(Flex)(props => ({
    flexDirection: 'column',
}))
export const ContentCard1= styled(Box)(props => ({
    paddingBottom:50,
    paddingTop:130,
    textAlign:'center',

    "@media screen and (max-width:480px)": {
        paddingBottom:30,
        paddingTop:110,
    },
}))

export const CnmButton= styled(Link)(props => ({
    flexDirection: 'column',
    fontSize:'25px',
    color:'#000',
    background:'transparent',
    border:'2px solid #d12c41',
    borderRadius:100,
    padding:'12px 25px',
    textTransform:'uppercase',
    marginTop:'30px',
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',
    "&:hover": {
        background:'#d12c41',
        color:'#fff'
      }
}))

export const  ClasslistingBody = styled(Flex)(props => ({
    width:'100%',
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'flex-start',
    justifyContent:'space-between',
    gap:'30px',
}))

export const  FromSection = styled(Flex)(props => ({
    width:'100%',
    flexDirection:'column',
    flexWrap:'wrap',
    alignItems:'center',
    justifyContent:'center',
    background:'black',
    padding:'80px 0px',
    "@media screen and (max-width:767px)": {
        padding:'50px 0px',
    },
    "@media screen and (max-width:480px)": {
        padding:'30px 0px',
    },
}))

export const  ImageWrapper = styled(Box)(props => ({
    width:'100%',
    marginTop:'20px'
}))
export const  FormImage = styled(Image)(props => ({
    width:'100%',height:'100%',objectFit:'cover',objectPosition:'center',
}))

export const  ImageContainer = styled(Box)(props => ({
    ":after":{

        "@media screen and (max-width:991px)": {
            bottom:'0px',
        },
    },
}))