import React from "react";
import { Container, Box } from '@chakra-ui/react';
import * as Style from './PrivacyPolicy.style';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import MetaTitle from "./MetaTitle";

function PrivacyPolicy() {
    return (
        <>
            <MetaTitle title='Privacy Policy | Perpetua Fitness' />
            <Container maxW='container.xl' paddingTop='100px' paddingBottom='30px'>
                <Style.PrivacyPolicyContent>
                    <Heading title='Privacy Policy: ' variant='large'></Heading>
                    <Text title='Last Updated: 15/3/23' />
                    <Text title='CrossFit Ireland LTD (hereinafter “Perpetua Fitness”, “Perpetua” “we,” “our,” or “us”) is committed to safeguarding and handling your Personal Information in a responsible and secure manner.' padding='10px 0' />
                    <Text title='This Privacy Policy (“Policy”) describes how we process your Personal Information as you use our website located at www.perpetua.ie, visit our studios, and use any of the services, tools, technology, products, features, events, webpages, and mobile apps (together, the “Services”) provided, operated or owned by Perpetua. When we collect, access, use or do anything with your Personal Information, we are “processing” your Personal Information.' padding='10px 0' />
                    <Text title='We may modify this Policy at any time and non-material changes may apply to any Personal Information we already hold about you, as well as any new Personal Information collected after the Policy is modified. If we make changes, we will notify you by revising the date at the top of this Policy. If you continue to access or use our Services after the changes, you acknowledge your acceptance of the updated Policy. ' padding='10px 0' />
                    <Text title='This policy (together with our Terms and Conditions of Service) sets out the basis on which any personal data we collect from you, or that you provide to us, will be Please read our Terms of Service before accessing our Services. To the extent permitted under the applicable law, by accepting the Terms of Service, you agree with our privacy practices as described in this Policy. If you cannot agree with this Policy, Terms of Service, or other policies, please do not access or use our Services.' padding='10px 0' />
                    <Text title='processed by us. Please read the following carefully to understand what personal data we collect, how that data is used and the ways it can be shared by us and you. The data controller of your personal data is MindBody. ' padding='10px 0' />
                </Style.PrivacyPolicyContent>
                <Style.PrivacyPolicyContent>
                    <Heading title='Section 1: Information We Collect Through Use of Service ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='Personal Information is any information that is related to you, identifies you or makes you identifiable. We collect different types of Personal Information when you’re accessing or using our Services, including: ' />

                    <Box padding='20px 10px'>
                        <Box display='flex' padding='5px 0'>
                            <Heading title='Information You Provide to Us - ' variant='extrasmall' padding='' />
                            <Text title=' In connection with the Services, we may ask you to provide these categories of Personal Information: ' padding='0 5px' />
                        </Box>
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Identity data -</span> name, username and date of birth </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Contact data  - </span> billing address, email address or telephone numbers</Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Financial data -</span> bank account and payment card details </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Transaction data - </span> details about payments to and from you  </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Technical data -</span> internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access our website  </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Profile data - </span> your username and password, your preferences, feedback and survey responses</Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Usage data - </span> information about how you use our website and services </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Marketing and communications data - </span> your preferences in receiving marketing from us and our third parties and your communication preferences </Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>Voluntary Information : </span> Information you provide to us voluntarily by completing web forms, submitting a job application, signing up for our newsletter, participating in polls, or contributing to blogs, postings, contacting customer service, and other mediums constitutes Voluntary Information.</Box>} padding='5px 0' />
                        <Text title={<Box><span style={{ fontStyle: 'bold', fontWeight: '550' }}>CCTV : </span>  we use CCTV in our locations for health and security reasons. The images are held securely and only accessed when needed.</Box>} padding='5px 0' />
                    </Box>
                    <Text title='We also collect, use and share aggregated data such as statistical or demographic data for any purpose. Aggregated data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your usage data to calculate the percentage of users accessing a specific website feature. However, if we combine or 
                connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy. ' padding='10px 0' />
                    <Text title='MindBody - we may receive your information through MindBody if you have signed up for our classes through MindBody. For further details on how MindBody collects and stores your information please click here https://company.mindbodyonline.com/legal/privacy-policy.' padding='10px 0' />
                </Style.PrivacyPolicyContent>

                <Style.PrivacyPolicyContent>
                    <Heading title='Section 2: How We Use Your Information ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='Your Personal Information can be used for various purposes, including: ' />
                    <Heading title='To Provide Our Services:' variant='extrasmall' padding='10px 5px' />
                    <Text title='When you access or use our Services, we process certain Personal Information about you to be able to provide you with our Services. Some examples of how we process your Personal Information in our Services include, but are not limited to: ' padding='0px 5px' />
                    <Style.PrivacySubText >
                        <Text title='i.' padding='0px 10px' ></Text>
                        <Text
                            title="To allow you to view, book, and attend classes at our studios  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='ii.' padding='0px 10px' ></Text>
                        <Text
                            title="To allow you to view, book and attend classes through our Branded MindBody X Perpetua Fitness App  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iii.' padding='0px 10px' ></Text>
                        <Text
                            title=" To pay for the Services. ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iv.' padding='0px 10px' ></Text>
                        <Text
                            title="To ensure a safe work-out environment, and  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='v.' padding='0px 10px' ></Text>
                        <Text
                            title="To analyze your use and enjoyment of the Services">
                        </Text>
                    </Style.PrivacySubText>
                    <Box padding='20px 10px'>
                        <Text title='We cannot provide you with our Services without such processing of your Personal Information. ' padding='10px 0 5px' />

                        <Heading title='To Communicate with You' variant='extrasmall' padding='10px 5px 5px' />
                        <Text title='When you create an account for our Services, you will receive Service-related communications. Our Service-related communications are transactional messages about your account, billing information, your attendance, surveys, support, customer service, discounts you’ve earned, policy changes, or other Service-related notifications. However, service-related communications are necessary for us to continue to provide our Services to you. ' padding='0px 5px' />

                        <Heading title='Marketing Communication.' variant='extrasmall' padding='10px 5px 5px' />
                        <Text title='We may also use your Personal Information for marketing, promotional, or sweepstakes related communications and initiatives. You can manage your email preferences and opt out of certain communications, by clicking the “unsubscribe” link found within non-transactional communications or by emailing us at the contact information listed at the end of this Policy. ' padding='0px 5px' />

                        <Heading title='To Enforce our Terms, Agreements, or Policies.' variant='extrasmall' padding='10px 5px 5px' />
                        <Text title='To maintain a safe, secure, and trusted environment for you when you use the Services, we use your Personal Information to make sure our terms, policies, and agreements with you and any third parties are enforced. We actively monitor, investigate, prevent, and mitigate any suspected or actual prohibited activities on our Services. We are required to ' padding='0px 5px' />

                        <Heading title='For Research and Development' variant='extrasmall' padding='10px 5px 5px' />
                        <Text title='We want to ensure our Services are continually improving and expanding so that we meet and exceed your needs and expectations. To do so, we may process your Personal Information to improve, optimize, or expand our Services or features of our Services. We do so by processing information about your use of the Services, any information you provide to us, and by measuring, tracking, and analyzing trends and usage in connection to your use of our Services. We take additional security measures when processing your Personal Information for such purposes, such as by de-identifying your Personal Information and limiting access to such data. Without processing your information for such purposes, we may not be able to ensure your continued satisfaction when using our Services.' padding='0px 5px' />
                    </Box>
                </Style.PrivacyPolicyContent>

                <Style.PrivacyPolicyContent>
                    <Heading title='Section 3: How We Share Your Information / Who We Share it With ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='We may share your Personal Information as follows: ' />
                    <Box padding='20px 0 0'>
                        <Heading title='Third-Party Service Providers' variant='extrasmall' />
                        <Text title='We may use third parties to perform certain services on our behalf in connection with the Services such as: ' padding='5px 0' />
                        <Style.PrivacySubText >
                            <Text title='i.' padding='0px 10px' ></Text>
                            <Text
                                title="to process and store data, including your Personal Information; ">
                            </Text>
                        </Style.PrivacySubText>
                        <Style.PrivacySubText >
                            <Text title='ii.' padding='0px 10px' ></Text>
                            <Text
                                title="to track, analyze, and modify our Services; ">
                            </Text>
                        </Style.PrivacySubText>
                        <Style.PrivacySubText >
                            <Text title='iii.' padding='0px 10px' ></Text>
                            <Text
                                title="for marketing, advertising, and distribution; ">
                            </Text>
                        </Style.PrivacySubText>

                        <Style.PrivacySubText >
                            <Text title='iv.' padding='0px 10px' ></Text>
                            <Text
                                title="to assist us in providing you with customer support; and  ">
                            </Text>
                        </Style.PrivacySubText>
                        <Style.PrivacySubText >
                            <Text title='v.' padding='0px 10px' ></Text>
                            <Text
                                title="to support our IT and security efforts.  ">
                            </Text>
                        </Style.PrivacySubText>
                    </Box>
                    <Text title='However, certain third-party service providers have their own privacy policies, in respect to the information we are required to provide to them for your purchase-related transactions.' padding='10px 0' />
                    <Text title='For these providers, we recommend that you read their respective privacy policies so you can understand the manner in which your Personal Information will be handled by these providers. In particular, remember that certain providers may be located in, or have facilities that are located in, a different jurisdiction than either you or us. If you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. ' padding='10px 0' />
                </Style.PrivacyPolicyContent>

                <Style.PrivacyPolicyContent>
                    <Heading title='Section 4: Marketing ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='We may use your personal information to tell you about relevant services and any upcoming offers. ' padding='10px 0' />
                    <Text title='We can only use your personal information to send you marketing messages if we have either your consent or a legitimate interest to do so.  ' padding='10px 0' />
                    <Text title='You can ask us to stop sending you marketing messages at any time – you just need to contact us or use the opt-out links on any marketing message sent to you. ' padding='10px 0' />
                    <Text title='We will get your express opt-in consent before we share your personal data with any company other than us for marketing purposes.  You can ask a third-party company to stop sending you marketing messages at any time, by adjusting your marketing preferences in relation to that company or by using the opt-out links on any marketing message sent to you. ' padding='10px 0' />
                    <Text title='Where you opt out of receiving marketing messages, this will not apply to personal data provided to us as a result of purchasing services or any other transaction between you and us.' padding='' />
                </Style.PrivacyPolicyContent>
                <Style.PrivacyPolicyContent>
                    <Heading title='Section 5: Cookies ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='We use cookies to distinguish you from other users of the Services. This helps us to provide you with a satisfactory experience when you use or access the Services and also allows us to improve the Services. ' padding='10px 0' />

                    <Heading title='What are Cookies?' variant='extrasmall' padding='0px 0px 10px' />
                    <Text title='A cookie is a small text file that can be placed on your device (e.g. computer, tablet, mobile phone) when browsing a website. Two kinds of cookies are used: session cookies and persistent cookies' />
                    <Style.PrivacySubText >
                        <Text title='i.' padding='0px 10px' ></Text>
                        <Text
                            title="Session cookies are used to remember selections made on a visit to the Site. Session cookies are deleted automatically when you leave the Site or close your browser. Persistent cookies are used to identify you when you return to the Site and/or to remember specific information about your preferences. These cookies also help us understand user behaviour within the Site, which can assist us in customising content that we believe will be most relevant to your interests. Persistent cookies are stored on the user’s device and are not deleted when the browser is closed – these cookies must be ‘manually’ deleted if you want to remove them.  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='ii.' padding='0px 10px' ></Text>
                        <Text
                            title="First and third-party cookies: We set cookies (first party cookies) on web pages, however, where we require additional information and services, we also allow other companies to host cookies on our web pages (third party cookies). These partner companies have been carefully selected by us. Third party cookies that our partners set will support the customisation of advertisements viewed elsewhere on the internet and are required to meet contractual obligations they have with us. To learn more about cookies, please visit www.allaboutcookies.org ">
                        </Text>
                    </Style.PrivacySubText>

                    <Heading title='What do we use cookies for? ' variant='extrasmall' padding='20px 0px 10px' />
                    <Text title='The cookies we use are categorised into five groups:' />
                    <Style.PrivacySubText >
                        <Text title='i.' padding='0px 10px' ></Text>
                        <Text
                            title="Strictly Necessary cookies - these cookies are used for technical reasons and are necessary to enable the Site to operate efficiently so that you can navigate the Site and use the Services with ease. These include, for example, cookies that enable you to log into secure areas of the Site. If these cookies are blocked or disabled, some of the Site’s functions will not be available to you and you may not be able to use the Services. ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='ii.' padding='0px 10px' ></Text>
                        <Text
                            title="Performance cookies - these cookies are used to obtain statistics about the number of users of the Site/Services and how such users interact with the Site/Services. These cookies collect information that is aggregated and therefore cannot be used to reveal an individual’s identity. Such information allows us to continuously improve the Site and Services to provide users with a better online experience (such as enabling users to find what they are looking for more easily). ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iii.' padding='0px 10px' ></Text>
                        <Text
                            title=" Functionality cookies - these cookies are used to improve the functionality of the Site and make it easier to use the Services. They help us to identify you as a repeat user of the Services and help us remember your preferences. These cookies help us remember information about you that saves you time by making your user experience easier. ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iv.' padding='0px 10px' ></Text>
                        <Text
                            title="Analytical - these cookies gather information about your habits when using the Services (including the pages you have visited and the links you have followed). We may combine such information with other personal data that we have collected about you (for example from third parties) to create a user profile for you. These cookies, in combination with any user profile that you have created assist us to make the Site and Services more relevant to you (i.e. by helping us tailor our content, marketing communications, offers and advertisements to your interests.) ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='v.' padding='0px 10px' ></Text>
                        <Text
                            title="Targeting - these cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and Services more relevant to your interests. We may also share this information with third parties for marketing purposes, unless you disable or block cookies as set out below.  ">
                        </Text>
                    </Style.PrivacySubText>


                    <Heading title='How do I control my cookie settings on my computer?' variant='extrasmall' padding='20px 0px 10px' />
                    <Text title=' Please be aware that if you decide to disable or block cookies, parts of the Site may not function correctly, or at all, and we may not be able to offer some or all of the Services to you. Should you choose, however, to disable or block our cookies on your device you will need to do this through your browser. Click on the ‘Help’ menu on your particular browser to learn how to manage your cookie preferences. Alternatively, you can visit www.aboutcookies.org or www.youronlinechoices.eu for comprehensive information on how to manage cookies.  ' />

                    <Heading title='What about from mobile applications? ' variant='extrasmall' padding='20px 0px 10px' />
                    <Text title='When you download and/or use the Perpetua Fitness App on your device, we automatically collect information from your device, as set out in section 1, 2, 3. If you do not wish us to collect information as described in this section, you should stop using our Services.' />
                </Style.PrivacyPolicyContent>

                <Style.PrivacyPolicyContent>
                    <Heading title='Section 6: Your Rights ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='You have certain rights relating to how we process your personal data, including the right to: ' />
                    <Style.PrivacySubText >
                        <Text title='i.' padding='0px 10px' ></Text>
                        <Text
                            title="Access the personal data that we may hold about you. If you wish to exercise this right, please contact us letting us know that you wish to exercise your right of access and what information in particular you would like to receive. Please note that we may not be able to provide all the information you ask for, for instance if the information includes personal information about another person. Where we are not able to provide you with information that you have asked for, we will endeavour to tell you why. We will try to respond to any request for a right of access as soon as possible, but we will always do so within 1 month of receipt of your request and evidence of relevant ID; ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='ii.' padding='0px 10px' ></Text>
                        <Text
                            title="Rectify any inaccurate personal data that we may hold about you. If you realise that any of the information that we hold about you is incorrect, please let us know and we will correct it as soon as we can;  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iii.' padding='0px 10px' ></Text>
                        <Text
                            title="Have your personal data erased in certain circumstances, for example, where it is no longer necessary for us to process your personal data or where you object to your data being processed on certain grounds (for example, for marketing purposes). Please note that to ensure that we do not collect any further information you should also delete our app from your mobile devices and clear our cookies from any device where you have accessed our Services. Where we delete personal information about you, we may still retain some or all of that information for other purposes such as maintaining financial records, protecting or enforcing legal rights, maintaining marketing suppression lists or for technical reasons such as maintaining technical security or our database integrity. We may also retain your information in an anonymised form;  ">
                        </Text>
                    </Style.PrivacySubText>
                    <Style.PrivacySubText >
                        <Text title='iv.' padding='0px 10px' ></Text>
                        <Text
                            title="Object to your personal data being processed for direct marketing purposes. If you have chosen to receive direct marketing communications from us, you can change your mind at any time by following the unsubscribe link in any marketing communication that is sent to you. If you have chosen to receive more than one type of marketing communications from us, you may need to opt out of all of them individually. It may take a few days for us to update our records before any optout is effective. ">
                        </Text>
                    </Style.PrivacySubText>
                    <Text title='Should you wish to exercise any of these rights, please contact us at hello@perpetua.ie. The site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note these websites have their own privacy policies and we do not accept any liability for these policies. Please check these policies before you submit any personal data to these websites. ' padding='10px 0' />

                </Style.PrivacyPolicyContent>

                <Style.PrivacyPolicyContent>
                    <Heading title='Section 7: Changes to Our Privacy Policy & Cookies Policy  ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently for any updates. ' />
                </Style.PrivacyPolicyContent>
                <Style.PrivacyPolicyContent>
                    <Heading title='Section 8: Contact Information  ' variant='smallmid' padding='0px 0px 10px' />
                    <Text title='Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to hello@perpetua.ie. Should you have cause to complain about how we handle your personal data, please contact us in the first instance. We will do our best to resolve your concern. Alternatively, you may prefer to submit a complaint directly to the Information Commissioner’s Office (ICO). ' />

                </Style.PrivacyPolicyContent>
            </Container>

        </>
    )
}

export default PrivacyPolicy;