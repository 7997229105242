import React from "react";
import { Box, Container, Flex } from '@chakra-ui/react';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import { Helmet } from "react-helmet";
import MetaTitle from "./MetaTitle";


function SignUp(){
    return(
        <>
            <MetaTitle title='Sign Up | Perpetua Fitness'/>
            <Box textAlign='center' paddingTop='130px'>
                <Heading title='CREATE AN ACCOUNT!' />
                <Text title='Use your Perpetua account to book classes and purchase class packs and memberships' />
            </Box>
            <Box  >
                <Container maxW='container.lg' padding={{sm:'0px 15px 60px', md:'50px 15px',lg:'20px 15px 50px'}}>
                    <Helmet className='sigup'>
                        <script  src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript" />
                    </Helmet>
                    <healcode-widget data-type="registrations" data-widget-partner="object" data-widget-id="70507370014" data-widget-version="0"></healcode-widget>
                    {/* <healcode-widget data-type="registrations" data-widget-partner="object" data-widget-id="70507370014" data-widget-version="0"></healcode-widget> */}
                </Container>
            </Box>   
        </>
    )
}
export default SignUp;