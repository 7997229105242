import React from "react";
import { Box, Container,Link } from '@chakra-ui/react';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import CancellationForm from "../component/Form/CancellationForm";
import MetaTitle from "./MetaTitle";

function Cancellation(){
    return(
        <>
            <MetaTitle title='Cancellation | Perpetua Fitness'/>
            <Box padding='130px 15px'>
                <Container maxW='container.xl'>
                    <Box >
                        <Text title='We’re sorry you’re taking a break!' />
                        <Text title='Please fill in the form below to cancel your contract.' padding='5px 0' />
                        <Text title="We require 30 to 60  day's notice to cancel a contract early depending on the membership you currently have." padding='5px 0' />
                        <Text title='Any payments due within the notice period in your contract agreement from the date this form is completed will still be processed.' padding='5px 0' />
                        <Text title={<> As a gym, we hope you’ll keep exercising, so if you would like to follow our online programs, please visit <Link href='https://perpetuatraining.com/' textDecoration='underline' isExternal='true' > www.perpetuatraining.com.</Link> </>} padding='5px 0'/>
                        <Text title='Hope to see you again very soon!' padding='5px 0' />
                       
                    </Box>
                    <Box paddingTop='30px'>
                        <Heading title='Please cancel my contract:-' color='#d12c41' variant='small' padding='10px 0' />
                        <Heading title='Please fill in the below:'  variant='smallmid' padding='10px' />
                        <CancellationForm/>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
export default Cancellation;