import React from 'react';
import { SimpleGrid, Box, Flex, Heading as Heading2, GridItem } from '@chakra-ui/react';
import Text from '../SubHeading/SubHeading';
import Heading from '../Heading/Heading';
import PriceCard3 from './PriceCard3';


const HyroxPricingWrapper = () => {
    return (
        <>
            <Box padding={{ sm: "0 0 30px", md: '0 0 50px' }}>
                <Heading title='Train for less' variant='extrasmall' />
                <Heading2 paddingTop='7px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>HYROX membership</Heading2>
                <SimpleGrid columns={{ sm: "1", md: "3" }} spacing={10} margin='30px 0px'>
                    <GridItem>
                        <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }} ><i class="bi bi-tag-fill"></i></Box>
                        <Heading title='TRAIN FOR LESS' variant='smallmid' padding='10px 0' />
                        <Text title='Our HYROX membership works out much cheaper for you to attend our Hyrox classes.' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-trophy"></i></Box>
                        <Heading title='EARLY ACCESS BOOKING' variant='smallmid' padding='10px 0' />
                        <Text title='Perpetua members will enjoy an exclusive 6-day booking window, meaning you can get in and book your class a day before everyone else and secure your workout.' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-lightning-charge"></i></Box>
                        <Heading title='JustGYM Access' variant='smallmid' padding='10px 0' />
                        <Text title='Our JustGYM facility is located at our Windmill Lane location' color='#000' />
                    </GridItem>
                </SimpleGrid>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="32" offer="" cardTitle="" currency="€215" planname="MEMBERSHIP" description="• 13 HYROX classes a week • JustGYM Access • Access to HYROX Program via TrainHeroic" buttonTitle='Buy Now' targetBlank="_blank" link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=158' />

            </SimpleGrid>
            {/*  */}
            <Box  padding={{sm:"70px 0 30px",md:'100px 0 50px'}}>
                <Heading title='Flexible Training' variant='extrasmall' />
                <Heading2 paddingTop='7px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>Studio Credit Packs</Heading2>
                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} margin='30px 0px'>
                
                    <GridItem>
                        <Heading title='Flexible' variant='smallmid' padding='10px 0' />
                        <Text title='Use the credits for any of our studio class within their expiry date' color='#000' />
                    </GridItem>
                    <GridItem> 
                        <Heading title='Access To ALL Studio Class' variant='smallmid' padding='10px 0' />
                        <Text title='Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class' color='#000' />
                    </GridItem>
                </SimpleGrid>
            </Box>
            <SimpleGrid columns={{ sm: "2", md: "2", lg: "3" }} spacing={{ sm: '5', md: '10' }}>

                <PriceCard3 key="2" currency="€33" planname="3 FOR €33" offer="best intro offer" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433" targetBlank='true' description="10 Day expiry" buttonTitle='Buy Now' tagTitle='OPENING OFFER' />

                <PriceCard3 key="3" currency="€25" planname="1 STUDIO CREDIT" offer="€25 per Credit" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104430" targetBlank='true' description="30 Day expiry" buttonTitle='BUY NOW' />
                
                <PriceCard3 key="4" currency="€70" planname="3 STUDIO CREDITS" offer="€23.33 per Credit" targetBlank='true' description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107732" buttonTitle='Buy Now' />

                <PriceCard3 key="5" offer="€22.40 per Credit" currency="€112" planname="5 STUDIO CREDITS" description="45 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104431" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="6" offer="€21.50 per Credit" currency="€215" planname="10 STUDIO CREDITS" description="3 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104432" targetBlank='true' buttonTitle='Buy Now' tagTitle='MOST POPULAR' />
                
                <PriceCard3 key="7" offer="€20 per Credit" currency="€300" planname="15 STUDIO CREDITS" description="6 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107733" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="8" offer="€18.20 per Credit" currency="€364" planname="20 STUDIO CREDITS" description="9 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=105433" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="9" offer="€17 per Credit" currency="€510" planname="30 STUDIO CREDITS" description="12 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107734" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="1" currency="€63" planname="STUDENT 3-PACK" offer="€21 per Credit" buttonTitle='Enquire NOW' link="mailto:hello@perpetua.ie" targetBlank='true' description={<Box>30 Day expiry<br/>Must hold valid Student ID</Box>} tagTitle='STUDENT ONLY' />
            </SimpleGrid>

        </>
    )
}

export default HyroxPricingWrapper;
