import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Tabs, TabPanels, TabPanel,
    SimpleGrid, Button,
    TabList, Tab, Link
} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import './price.css';
import StudioPricingWraper from './StudioPricingWaper';
import { FaAngleDown } from "react-icons/fa";
import Text from '../SubHeading/SubHeading';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ButtonComponent from '../Button/CustomeButton';
import PriceCard3 from './PriceCard3';
import CrossFitPricingWrapper from './CrossFitPricingWrapper';
import JustGymPricingWrapper from './JustGymPricingWrapper';
import InnerBanner from '../InnerBanner/InnerBanner';
import GiftCardWrapper from './GiftCardWrapper';
import HyroxPricingWrapper from './HyroxPricingWrapper';

const bannerDetails = [
    {
        id: 1,
        bg_img: "",
        title: "Welcome To Perpetua",
        subTitle: "Start your Perpetua journey with our Intro Pack. Get 3 classes for 33 euros!",
        buttonTitle: "Buy Intro Pack",
        link: "https://cart.mindbodyonline.com/sites/76074/cart",
        isExternal: "true"
    },
    {
        id: 2,
        bg_img: "",
        title: "Welcome To CrossFit Dublin. Est 2008",
        subTitle: "Start your CrossFit journey by joining our CrossFit Academy",
        buttonTitle: "Book A Call",
        link: "/contact-us",
        isExternal: "false"
    },
    {
        id: 3,
        bg_img: "",
        title: "Welcome To Perpetua Fitness – A safe place to train",
        subTitle: "Start your Perpetua journey by joining our JustGYM – Our premium training facility",
        buttonTitle: "Buy Now",
        link: "https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=171",
        isExternal: "true"
    },
    {
        id: 4,
        bg_img: "",
        title: "Welcome To Perpetua Fitness – A safe place to train",
        subTitle: "Start your Perpetua journey by working with our experienced and friendly coaches",
        buttonTitle: "Buy Now",
        link: "/pricing/personal-training",
        isExternal: "false"
    },
    {
        id: 5,
        bg_img: "",
        title: "Welcome To Perpetua Fitness – HYROX training facility",
        subTitle: "Start your Perpetua journey by joining HYROX which will focus on building your endurance and strength",
        buttonTitle: "Book a class",
        link: "/timetable",
        isExternal: "false"
    },
]

function PricingWraper(props) {

    const tabMenu = ["STUDIOS", "CROSSFIT", "JUST GYM", "PERSONAL TRAINING", "HYROX", "PRE/POST NATAL", "NUTRITION", "ONLINE PROGRAMMING", "GIFT CARD", "LADIES"];

    const tabMenuUrl = ["STUDIOS", "CROSSFIT", "JUST GYM", "PERSONAL TRAINING", "HYROX", "PREPOST NATAL", "NUTRITION", "ONLINE PROGRAMMING", "GIFT CARD", "LADIES"];

    const [priceTab, setPriceTab] = useState(false);
    const [showTabName, setShowTabName] = useState('All Classes');

    const { tab } = useParams();
    const navigate = useNavigate();
    const initialTabIndex = tabMenuUrl.findIndex(
        (item) => item.toLowerCase() === tab?.toLowerCase().replace(/-/g, ' ')
    );
    const [tabIndex, setTabIndex] = useState(initialTabIndex !== -1 ? initialTabIndex : 0);


    const handelTabItem = () => {
        setPriceTab(!priceTab);
    }

    useEffect(() => {
        if (tab) {
            const newTabIndex = tabMenuUrl.findIndex((item) => item.toLowerCase() === tab.toLowerCase().replace(/-/g, ' '));
            if (newTabIndex === -1) {
                navigate(`/pricing`);
            }
            setTabIndex(newTabIndex !== -1 ? newTabIndex : 0);
            setShowTabName(tabMenuUrl[newTabIndex]);
        }
    }, [tab]);

    const handleTabChange = (index) => {
        setTabIndex(index);
        setPriceTab(!priceTab);
        setShowTabName(tabMenuUrl[index]);
        navigate(`/pricing/${tabMenuUrl[index].toLowerCase().replace(/\s+/g, '-')}`);
    };

    const currentBanner = bannerDetails[tabIndex];

    return (
        <>
            {/* {tabIndex == 0 &&
                <InnerBanner
                    class='pricing-page-banner'
                    backgroundColor='#fff'
                    backgroundImage={{ sm: 'url(../images/ResolutionPackBanner22mobile1.webp)', md: 'url(../images/ResolutionPackBannerPricing.webp)' }}
                    isHeading={false}
                    issubtextpara={false}
                    isButton={false}
                    issubtitleRight={false}
                    isSubtext={true}
                    subtitle='perpetua resolution packs'
                    isButton2={true}
                    buttontitle2={'Buy Now'}
                    Link2={'/resolution-packs'}
                    margin={'15px 0 0'}
                    width={{ sm: "90%", md: '70%', lg: '80%', xl: "100%" }}
                    height={{ sm: '450px', md: '300px', lg: '500px', xl: '500px' }}
                />
            } */}


            {tabIndex >= 0 && tabIndex <= 4 &&
                <Box
                    alignItems="center"
                    justifyContent="center"
                    backgroundImage='url(../images/RumbleLanding.png)'
                    backgroundColor='black'
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    height={{ sm:'50vh', md:'420px', lg:'430px' }}
                    backgroundSize={"cover"}
                    display='flex'
                    flexDirection='column'
                >
                    <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} >
                        <Box
                            maxW={props.maxW ? props.maxW : { sm: '100%', md: '700px' }}
                            minWidth={{ sm: '100%', md: '500px' }}
                            paddingTop={"40px"}
                            paddingBottom={"60px"}
                            textAlign={props.textAlign}
                            textTransform="uppercase"
                            margin={props.align}
                            className='wow animate fadeInUp'
                        >
                            <Heading title={currentBanner.title} color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />
                            <Text as='strong' fontSize={{ base: 'xs', md: "xs" }} color='#fff' title={currentBanner.subTitle} fontFamily='BlenderProBold'></Text>
                            <Box>
                                {currentBanner.isExternal == 'true' ?
                                    <Link href={currentBanner.link} isExternal='true'>
                                        <ButtonComponent margin='20px 0 0' title={currentBanner.buttonTitle} />
                                    </Link> :
                                    <NavLink to={currentBanner.link}>
                                        <ButtonComponent margin='20px 0 0' title={currentBanner.buttonTitle} />
                                    </NavLink>
                                }
                            </Box>
                        </Box>
                    </Container>
                </Box>
            }

            {tabIndex == 8 &&

                <Box
                    alignItems="center"
                    justifyContent="center"
                    backgroundImage='url(../images/pricinggiftBanner1.webp)'
                    backgroundColor='black'
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    height={{ sm: '50vh', md: '420px', lg: '430px' }}
                    backgroundSize={"cover"}
                    display='flex'
                    flexDirection='column'
                >
                    <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} >
                        <Box
                            maxW={props.maxW ? props.maxW : { sm: '100%', md: '700px' }}
                            minWidth={{ sm: '100%', md: '500px' }}
                            paddingTop={"40px"}
                            paddingBottom={"60px"}
                            textAlign={props.textAlign}
                            textTransform="uppercase"
                            margin={props.align}
                            className='wow animate fadeInUp'
                        >
                            <Heading title='Empower Their Fitness Journey with Our Gym Training Gift Card!' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />

                            <Text as='strong' fontSize={{ base: 'xs', md: "xs" }} color='#fff' title='Give the gift of health and strength – the ultimate motivation to achieve fitness goals.' fontFamily='BlenderProBold' />
                            <Box>
                                <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=106558' isExternal='true'>

                                    <ButtonComponent margin='20px 0 0' title='Book now' />
                                </Link>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            }

            <Box p={{ sm: '50px 0px', md: '70px 0px' }}>
                <Container className='' maxW='container.xl' >
                    <Tabs className='pricing_tab' index={tabIndex} onChange={handleTabChange} variant='unstyled' pr={{ sm: "10px" }}>
                        <Box className='-small-view-body'>
                            <Button onClick={handelTabItem} display='flex' alignItems='center' justifyContent='space-between' className='-small-dropdown-button'>
                                <Text as='span' title={showTabName} />
                                <FaAngleDown />
                            </Button>
                            {priceTab ? <TabList className="ptList ptListALL" m={{ sm: '0 0 30px', md: '0 0 30px' }} >
                                {tabMenu.map((item, index) => {
                                    return (
                                        <Tab key={index} p="0" justifyContent={'flex-start'} className={`tabBtn ${index === tabIndex ? "activeTab" : ""}`}>
                                            {item}
                                        </Tab>
                                    )
                                })}
                            </TabList> : null}
                        </Box>

                        <TabPanels>
                            <TabPanel>
                                <StudioPricingWraper />
                            </TabPanel>
                            <TabPanel>
                                <CrossFitPricingWrapper />
                            </TabPanel>
                            <TabPanel>
                                <JustGymPricingWrapper />
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ sm: "1", md: "2", }} spacing={10}>
                                    <PriceCard3 key="28" offer="" cardTitle="" currency="Package1" planname="10 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered. " buttonTitle='Enquire Now' link='/join-today' />

                                    <PriceCard3 key="29" offer="" cardTitle="" currency="Package2" planname="15 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered. " buttonTitle='Enquire Now' link='/join-today' />

                                    <PriceCard3 key="30" offer="" cardTitle="" currency="Package3" planname="20 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered." buttonTitle='Enquire Now' link='/join-today' />

                                    <PriceCard3 key="31" offer="" cardTitle="" currency="Monthly Package" planname="Small Group Personal Training" description="Get the best out of small group personal training where you will enjoy the group
                                    atmosphere matched with the individual attention."  buttonTitle='Enquire Now' link='/join-today' />
                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <HyroxPricingWrapper />
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                                    <PriceCard3 key="34" offer="" cardTitle="" currency="€300" planname="Post Natal 6 week Course" description="This 6 week course includes 2 sessions a week and is perfect for postpartum moms who want to ease back into exercise AND socialise with other mummys!" buttonTitle='Enquire Now' link='/contact-us' />

                                    <PriceCard3 key="35" offer="starting at" cardTitle="" currency="€70" planname="PT session" description="Perfect for pre or postnatal moms who want one-to-one in person coaching and super-personalised programming" buttonTitle='Enquire Now' link='/contact-us' />

                                    <PriceCard3 key="36" offer="" cardTitle="" currency="€150" planname="Pre Natal 6 week Course" description="This 6 week course includes 1 sessions a week and is perfect for soon to be moms who want to continue to exercise AND socialise with other mummys!" buttonTitle='Enquire Now' link='/contact-us' />

                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                                    <PriceCard3 key="37" offer="" cardTitle="" currency="€99" planname="8 week program" description="" buttonTitle='Buy Now' targetBlank="_blank" link='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107856' />

                                    <PriceCard3 key="38" offer="" cardTitle="" currency="€30" planname="Inbody Check" description="Receive a full In-Body check with our team along with detailed results. " buttonTitle='Enquire Now' link='/contact-us' />
                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                                    <PriceCard3 key="39" offer="" cardTitle="" currency="€30" planname="1 month" description="We have 4 online programs which you can follow. Each program has a 7 day free trial period. Check out our page for more info on each program" buttonTitle='Find Out' link='https://perpetuatraining.com/' targetBlank='true' />
                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <GiftCardWrapper isHeading='true' isColums='true' />
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                                    <PriceCard3 key="41" offer="" cardTitle="" currency="€300" targetBlank="_blank" planname="LADIES GROUP" description=" Sign up to our 6 week course which has 3 sessions a week. The group is for females
                                    who are looking to learn how lift correctly, build strength and gain more confidence in a gym setting." buttonTitle='Find Out' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=102276' />
                                </SimpleGrid>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Box>
        </>
    )
}

export default PricingWraper;
