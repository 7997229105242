import {  Link,Box } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const Wrapper= styled(Box)(props => ({
    backgroundColor:'#000',
    borderBottom:'1px solid #454545',
    paddingBottom:'100px'
}))

export const Header= styled(Box)(props => ({
    backgroundColor:'#000',
    padding:'130px 0 0',
    borderTop:'1px solid #454545',
    textAlign:'center'
}))